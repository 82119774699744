// src/components/Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Box, TextField, Button, Typography, Container, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Importar el icono de WhatsApp

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true); // Activar por defecto
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Cargar los datos guardados si existen
    const savedUsername = localStorage.getItem('rememberedUsername');
    const savedPassword = localStorage.getItem('rememberedPassword');

    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Normaliza el nombre de usuario (sin espacios y en minúsculas)
      const normalizedUsername = username.trim().toLowerCase();

      // Referencia al documento del usuario en Firestore
      const userDocRef = doc(db, 'users', normalizedUsername);
      const userDoc = await getDoc(userDocRef);

      // Verificar si el documento del usuario existe
      if (!userDoc.exists()) {
        setError('El usuario no existe');
        return;
      }

      // Obtener los datos del usuario
      const userData = userDoc.data();

      // Verificar si la contraseña es correcta
      if (userData.password !== password) {
        setError('Contraseña incorrecta');
        return;
      }

      // Guardar en localStorage si la opción "Recordar mis datos" está activada
      if (rememberMe) {
        localStorage.setItem('rememberedUsername', normalizedUsername);
        localStorage.setItem('rememberedPassword', password);
      } else {
        localStorage.removeItem('rememberedUsername');
        localStorage.removeItem('rememberedPassword');
      }

      // Guardar el nombre de usuario en localStorage y redirigir al chat
      localStorage.setItem('username', normalizedUsername);
      navigate('/chat');
    } catch (error) {
      console.error('Error logging in:', error);
      setError('Error al iniciar sesión. Inténtalo de nuevo.');
    }
  };

  const handleWhatsAppClick = () => {
    const whatsappUrl = "https://wa.me/18298415339?text=Hola,%20necesito%20ayuda%20con%20mi%20inicio%20de%20sesión.";
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Container maxWidth="sm">
      <Box 
        sx={{ 
          mt: 8, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center' 
        }}
      >
        <Typography component="h1" variant="h5">
        Acceso
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Nombre de usuario"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="La Clave es de 4 números"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            inputProps={{ maxLength: 4 }}
          />
          <FormControlLabel
            control={
              <Checkbox 
                checked={rememberMe} 
                onChange={(e) => setRememberMe(e.target.checked)} 
                color="primary" 
              />
            }
            label="Recordar mis datos"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
           Acceso
          </Button>
        </Box>

        {/* Botón de WhatsApp */}
        <IconButton
          color="primary"
          sx={{ mt: 2 }}
          onClick={handleWhatsAppClick}
        >
          <WhatsAppIcon sx={{ fontSize: 40, color: 'green' }} />
        </IconButton>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Contáctanos por WhatsApp
        </Typography>
      </Box>
    </Container>
  );
}

export default Login;
