import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { setDoc, doc, getDoc, serverTimestamp, query, collection, where, getDocs } from 'firebase/firestore';
import { Box, TextField, Button, Typography, Container } from '@mui/material';

function SignUp() {
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();

    // Validar que la clave sea de 4 dígitos numéricos
    if (!/^\d{4}$/.test(password)) {
      setError('La clave debe ser un número de 4 dígitos');
      return;
    }

    // Validar que el número de teléfono tenga entre 10 y 12 dígitos
    if (!/^\d{10,12}$/.test(phone)) {
      setError('Por favor introduce un número de teléfono valido.');
      return;
    }

    // Validar que el nombre de usuario no sea un número de teléfono o un correo electrónico
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d{10,12}$/;
    if (emailPattern.test(username) || phonePattern.test(username)) {
      setError('El nombre de usuario no puede ser un correo electrónico ni un número de teléfono.');
      return;
    }

    // Validar que el nombre de usuario no tenga más de 3 números
    const numberCount = (username.match(/\d/g) || []).length;
    if (numberCount > 3) {
      setError('El nombre de usuario no puede contener más de 3 números.');
      return;
    }

    try {
      // Normalizar los campos
      const normalizedUsername = username.trim().toLowerCase();
      const normalizedFullName = fullName.trim().toLowerCase();
      const normalizedPhone = phone.trim();

      // Verificar si el nombre de usuario ya existe
      const userDocRef = doc(db, 'users', normalizedUsername);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        setError('El nombre de usuario ya está en uso');
        return;
      }

      // Verificar si el número de teléfono ya está registrado
      const phoneQuery = query(collection(db, 'users'), where('phone', '==', normalizedPhone));
      const phoneSnapshot = await getDocs(phoneQuery);
      if (!phoneSnapshot.empty) {
        setError('Este número de teléfono ya está asociado a otra cuenta');
        return;
      }

      // Guardar los datos del usuario en Firestore, incluyendo la fecha de creación
      await setDoc(userDocRef, {
        fullName: normalizedFullName,
        username: normalizedUsername,
        phone: normalizedPhone,
        password, // En producción, deberías cifrar esta contraseña
        createdAt: serverTimestamp(),
        avatarUrl: '',
        online: false,
        firstTime: true, // Aquí agregamos el campo firstTime
      });

      console.log('Usuario registrado:', normalizedUsername);
      setError('');

      // Guardar el nombre de usuario en localStorage y redirigir al chat
      localStorage.setItem('username', normalizedUsername); // Guardar el nombre de usuario en localStorage
      navigate('/chat'); // Redirigir al chat
    } catch (error) {
      console.error('Error al registrar usuario:', error);
      setError(error.message);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box 
        sx={{ 
          mt: 8, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center' 
        }}
      >
        <Typography component="h1" variant="h5">
          Inscribirse
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <Box component="form" onSubmit={handleSignUp} sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="fullName"
            label="Nombre Completo"
            name="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Nombre de Usuario"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Número de teléfono"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            inputProps={{ maxLength: 12 }} // Limitar la entrada a 12 dígitos
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Clave de 4 Dígitos"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            inputProps={{ maxLength: 4 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Inscribirse
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default SignUp;
