import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getDoc } from 'firebase/firestore';



const firebaseConfig = {
    apiKey: "AIzaSyC1fvK5EIYHFoPpxNvwHTyxpmkrr5DcHI0",
    authDomain: "otra-151ec.firebaseapp.com",
    databaseURL: "https://otra-151ec-default-rtdb.firebaseio.com",
    projectId: "otra-151ec",
    storageBucket: "otra-151ec.appspot.com",
    messagingSenderId: "77245400675",
    appId: "1:77245400675:web:a820bd33893dc5881e5a56"
};
const app = initializeApp(firebaseConfig);

// Exporta las instancias de servicios
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };