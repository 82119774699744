import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, onSnapshot, query, orderBy, where, Timestamp, updateDoc, doc, limit } from 'firebase/firestore';
import { Box, Typography, List, ListItem, ListItemText, Avatar, Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import SecurityIcon from '@mui/icons-material/Security';
import PersonIcon from '@mui/icons-material/Person';

// Función para calcular el tiempo transcurrido desde la última vez que el usuario se conectó
const timeSince = (timestamp) => {
  const now = new Date();
  const secondsPast = (now - new Date(timestamp.seconds * 1000)) / 1000;

  if (secondsPast < 60) {
    return `${Math.floor(secondsPast)} segundos`;
  }
  if (secondsPast < 3600) {
    return `${Math.floor(secondsPast / 60)} minutos`;
  }
  if (secondsPast < 86400) {
    return `${Math.floor(secondsPast / 3600)} horas`;
  }
  return `${Math.floor(secondsPast / 86400)} días`;
};

// Función para devolver el ícono basado en el rol del usuario
const getRoleIcon = (role) => {
  switch (role) {
    case 'oro':
      return <StarIcon style={{ color: 'gold' }} />;
    case 'platino':
      return <StarOutlineIcon style={{ color: 'lightblue' }} />;
    case 'moderador':
      return <SecurityIcon style={{ color: 'green' }} />;
    default:
      return <PersonIcon style={{ color: 'gray' }} />;
  }
};

function ConnectedUsers() {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [mostActiveUser, setMostActiveUser] = useState(null); // Para almacenar el usuario más activo
  const [userConnections, setUserConnections] = useState({}); // Para almacenar las conexiones de cada usuario
  const navigate = useNavigate();
  const role = localStorage.getItem('role'); // Obtener el rol del usuario actual

  useEffect(() => {
    // Obtener la fecha de hace 4 días
    const fourDaysAgo = Timestamp.fromDate(new Date(Date.now() - 4 * 24 * 60 * 60 * 1000));

    const unsubscribe = onSnapshot(
      query(
        collection(db, 'users'),
        where('lastOnline', '>=', fourDaysAgo), // Filtrar usuarios que se conectaron hace menos de 4 días
        orderBy('lastOnline', 'desc'), // Ordenar por `lastOnline` en orden descendente
        limit(20) // Limitar a los últimos 20 usuarios
      ),
      (snapshot) => {
        const users = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOnlineUsers(users);

        // Calcular cuántas veces se conectó cada usuario
        const connectionCount = {};
        users.forEach((user) => {
          connectionCount[user.username] = (connectionCount[user.username] || 0) + 1;
        });
        setUserConnections(connectionCount);

        // Determinar el usuario más activo
        const activeUser = Object.keys(connectionCount).reduce((a, b) => connectionCount[a] > connectionCount[b] ? a : b, null);
        setMostActiveUser(activeUser);
      }
    );

    return () => unsubscribe();
  }, []);

  const handleBlockUser = async (username) => {
    try {
      const userDocRef = doc(db, 'users', username);
      await updateDoc(userDocRef, {
        isBlocked: true,
        online: false, // Desconectar al usuario inmediatamente
      });
      alert(`Usuario ${username} ha sido bloqueado.`);
    } catch (error) {
      console.error("Error al bloquear al usuario:", error);
      alert("Hubo un error al intentar bloquear al usuario.");
    }
  };

  return (
    <Box sx={{ padding: 4, maxWidth: '800px', margin: '0 auto' }}>
      <Paper elevation={3} sx={{ padding: 3, borderRadius: '10px' }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
          Usuarios Conectados
        </Typography>
        <List>
          {onlineUsers.length > 0 ? (
            onlineUsers.map((user) => (
              <ListItem
                key={user.username}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 2,
                  borderBottom: '1px solid #e0e0e0',
                  '&:hover': { backgroundColor: '#f5f5f5' },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar src={user.avatarUrl} sx={{ mr: 2, width: 56, height: 56 }} />
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {user.username} ({user.role}) {getRoleIcon(user.role)}
                      </Box>
                    }
                    primaryTypographyProps={{ fontWeight: 'bold', color: '#424242' }}
                    secondary={
                      user.lastOnline ? (
                        <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#757575' }}>
                          Última conexión: hace {timeSince(user.lastOnline)}
                        </Typography>
                      ) : (
                        <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#757575' }}>
                          Sin información de conexión
                        </Typography>
                      )
                    }
                  />
                </Box>
                {role === 'moderador' && !user.isBlocked && (
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ borderRadius: '20px' }}
                    onClick={() => handleBlockUser(user.username)}
                  >
                    Bloquear
                  </Button>
                )}
              </ListItem>
            ))
          ) : (
            <Typography variant="body1" sx={{ textAlign: 'center', color: '#757575', fontStyle: 'italic' }}>
              No hay usuarios conectados.
            </Typography>
          )}
        </List>

        {/* Mostrar el usuario más activo */}
        {mostActiveUser && (
          <Typography variant="h6" sx={{ marginTop: 2, textAlign: 'center', color: '#1976d2' }}>
            Usuario más activo: {mostActiveUser} con {userConnections[mostActiveUser]} conexiones.
          </Typography>
        )}

        <Box sx={{ textAlign: 'center', marginTop: 3 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: '20px', padding: '10px 20px' }}
            onClick={() => navigate('/chat')}
          >
            Volver al Chat
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default ConnectedUsers;
