import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Chat from './components/Chat';
import Settings from './components/Settings';
import ConnectedUsers from './components/ConnectedUsers'; // Importar la nueva página
import Predictions from './components/Predictions';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/connected-users" element={<ConnectedUsers />} /> {/* Nueva ruta */}
        <Route path="/predictions" element={<Predictions />} /> {/* Ruta para Predictions */}

      </Routes>
    </Router>
  );
}

export default App;
