// src/components/Settings.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';
import { Container, Typography, Box, Avatar, Button, IconButton, CircularProgress } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';

function Settings() {
  const [role, setRole] = useState('normal');
  const [createdAt, setCreatedAt] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [avatar, setAvatar] = useState(null);
  const username = localStorage.getItem('username');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (!username) {
        console.error("Username es nulo. Redirigiendo al login...");
        navigate('/');
        return;
      }

      try {
        const userDocRef = doc(db, 'users', username);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const data = userDocSnap.data();
          let userRole = data.role || 'normal';
          setRole(userRole);
          setAvatarUrl(data.avatarUrl || '');

          if (data.createdAt) {
            const creationDate = data.createdAt.toDate();
            setCreatedAt(creationDate);

            let expiry;
            if (data.expiryDate) {
              expiry = data.expiryDate.toDate();
            } else {
              // Si no existe expiryDate, calcularlo y guardarlo en la base de datos
              expiry = new Date(creationDate);
              expiry.setMonth(expiry.getMonth() + 3);
              await updateDoc(userDocRef, { expiryDate: expiry });
            }

            setExpiryDate(expiry);

            // Verificar si la cuenta ha expirado
            if (expiry < new Date() && userRole !== 'normal') {
              // Si ha expirado, actualizar el rol a 'normal'
              await updateDoc(userDocRef, { role: 'normal' });
              setRole('normal');
              console.log('La cuenta ha expirado. Rol cambiado a usuario normal.');
            }
          }
        } else {
          console.warn("El documento del usuario no existe.");
        }
      } catch (error) {
        console.error("Error al obtener los datos del usuario:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [username, navigate]);

  const handleAvatarChange = (e) => {
    if (e.target.files[0]) {
      setAvatar(e.target.files[0]);
    }
  };

  const handleAvatarUpload = async () => {
    if (avatar) {
      try {
        const avatarRef = ref(storage, `avatars/${username}-${Date.now()}`);
        await uploadBytes(avatarRef, avatar);
        const url = await getDownloadURL(avatarRef);

        const userDocRef = doc(db, 'users', username);
        await updateDoc(userDocRef, { avatarUrl: url });

        setAvatarUrl(url);
        localStorage.setItem('avatarUrl', url);
      } catch (error) {
        console.error("Error al subir el avatar:", error);
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('avatarUrl');
    navigate('/'); // Redirige a la pantalla de inicio o de login
  };

  const handleBackToChat = () => {
    navigate('/chat'); // Redirige a la pantalla principal del chat
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box 
        sx={{ 
          mt: 8, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          bgcolor: 'background.paper',
          p: 4,
          borderRadius: 2,
          boxShadow: 3
        }}
      >
        <Typography component="h1" variant="h5" color="primary" gutterBottom>
          Configuración del Usuario
        </Typography>
        {avatarUrl && <Avatar src={avatarUrl} sx={{ width: 100, height: 100, mt: 2 }} />}
        <IconButton color="primary" component="label">
          <PhotoCamera />
          <input type="file" hidden onChange={handleAvatarChange} />
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2, mb: 2 }}
          onClick={handleAvatarUpload}
        >
          Subir nuevo Avatar
        </Button>
        <Typography variant="body1" sx={{ mt: 4 }}>
        <strong>Nivel de Usuario: {role.charAt(0).toUpperCase() + role.slice(1)}</strong>
        </Typography>
        {createdAt && (
          <Typography variant="body1" sx={{ mt: 2 }}>
            Cuenta creada el: {createdAt.toLocaleDateString()}
          </Typography>
        )}
        {expiryDate && (
          <Typography variant="body1" sx={{ mt: 1 }}>
            Cuenta expira el: {expiryDate.toLocaleDateString()}
          </Typography>
        )}

        {/* Nueva sección de balance con íconos */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" color="primary">
            Balance
          </Typography>
          <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <MonetizationOnIcon color="warning" /> 
            <strong>Moneda de Oro:</strong> 0
          </Typography>
          <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <MonetizationOnOutlinedIcon color="action" /> 
            <strong>Moneda de Plata:</strong> 0
          </Typography>
          <Typography variant="body1">
            <strong>Referidos:</strong> 0
          </Typography>
        </Box>

        <Box sx={{ mt: 4, display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            color="error"
            onClick={handleLogout}
          >
            Cerrar Sesión
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBackToChat}
          >
            Volver al Chat
          </Button>
        </Box>

        {/* Botón de WhatsApp */}
        <Button
          variant="contained"
          color="success"
          startIcon={<WhatsAppIcon />}
          sx={{ mt: 2 }}
          onClick={() => window.open('https://wa.me/18298415339', '_blank')}
        >
          Contactar por WhatsApp
        </Button>
      </Box>
    </Container>
  );
}

export default Settings;
