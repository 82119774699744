import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { db, storage } from '../firebase';
import { collection, addDoc, onSnapshot, serverTimestamp, orderBy, query, where, doc, updateDoc, getDoc, getDocs, limit, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Box, TextField, Button, Container, Typography, Paper, Avatar, IconButton, CircularProgress, Alert } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import DeleteIcon from '@mui/icons-material/Delete'; 
import ShareIcon from '@mui/icons-material/Share';

function Chat() {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [dailyMessageCount, setDailyMessageCount] = useState(0);
  const [isChatDisabled, setIsChatDisabled] = useState(false);
  const [role, setRole] = useState('normal');
  const [randomNumbers, setRandomNumbers] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isSendingAudio, setIsSendingAudio] = useState(false);
 

  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const audioRef = useRef(null);

  const username = localStorage.getItem('username');
  const avatarUrl = localStorage.getItem('avatarUrl');

  const highlightKeyword = (text) => {
    const keywords = ['PALE', 'SUPER PALE', 'QUINIELA', 'TRIPLETA'];
    const regex = new RegExp(`\\b(${keywords.join('|')})\\b`, 'gi');
    return text.replace(regex, `
      <strong style="
        font-size: 1.4em;
        background: linear-gradient(90deg, #fbc531, #f0932b, #e84118); /* Degradado de amarillo a naranja y rosa */
        -webkit-background-clip: text;
        color: transparent;
        font-family: Arial, Helvetica, sans-serif; /* Fuentes estándar */
        font-style: italic; /* Estilo cursiva */
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3); /* Añadimos sombra */
        text-transform: uppercase;
        padding: 2px 4px;
      ">$1</strong>
    `);
};


  const filterMessage = (message) => {
    const bannedWords = new Set(['whatsapp', 'ws', '809', '829', '849', 'dame tu contacto']); // Lista de palabras prohibidas
    let containsProfanity = false; // Para verificar si se detecta alguna grosería
  
    // Reemplazar las palabras prohibidas por ****
    const filteredMessage = message
      .split(' ') // Dividir el mensaje en palabras
      .map(word => {
        if (bannedWords.has(word.toLowerCase())) {
          containsProfanity = true; // Detectar que contiene una palabra prohibida
          return '****'; // Reemplazar por asteriscos
        }
        return word; // Devolver la palabra si no está prohibida
      })
      .join(' '); // Unir las palabras de nuevo en una sola cadena
  
    // Si se detectó una grosería, disparar el mensaje del sistema
    if (containsProfanity) {
      setTimeout(async () => {
        await addDoc(collection(db, 'messages'), {
          text: 'No se permite publicar números de contactos en este chat. Favor de revisar las reglas para evitar que su cuenta se cierre',
          username: '⚠️ !AVISO!',
          avatarUrl: '', // No mostrar avatar
          timestamp: serverTimestamp(),
          role: 'sistema',
        });
      }, 10000); // Esperar 10 segundos antes de mostrar el mensaje
    }
  
    return filteredMessage; // Devolver el mensaje filtrado (con o sin asteriscos)
  };








  



  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getUserStyle = (role) => {
    switch (role) {
      case 'oro':
        return { color: 'gold' };
      case 'platino':
        return { color: 'lightblue' };
      case 'moderador':
        return { color: 'green' };
      default:
        return { color: 'black' };
    }
  };

  useEffect(() => {
    const userDocRef = doc(db, 'users', username);

    const checkFirstTime = async () => {
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists() && userDoc.data().firstTime && !userDoc.data().welcomeMessageSent) {
        await addDoc(collection(db, 'messages'), {
          text: `¡${username} se ha unido al chat por primera vez! ¡Bienvenido/a!`,
          username: 'Sistema',
          avatarUrl: '',
          timestamp: serverTimestamp(),
          role: 'sistema',
        });

        await updateDoc(userDocRef, {
          firstTime: false,
          welcomeMessageSent: true,
        });
      }
    };

    const fetchAvatarUrl = async () => {
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const fetchedAvatarUrl = userData.avatarUrl;

        // Solo actualiza el localStorage si la URL ha cambiado
        if (fetchedAvatarUrl && fetchedAvatarUrl !== avatarUrl) {
          localStorage.setItem('avatarUrl', fetchedAvatarUrl);
        }
      }
    };

    checkFirstTime();
    fetchAvatarUrl();  // Obtener el avatar actualizado desde Firestore

    updateDoc(userDocRef, { online: true });

    const unsubscribeMessages = onSnapshot(
      query(
        collection(db, 'messages'),
        orderBy('timestamp', 'desc'),
        limit(50)
      ),
      (snapshot) => {
        const loadedMessages = snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
        setMessages(loadedMessages.reverse());
        scrollToBottom();
      }
    );

    const unsubscribeUserDoc = onSnapshot(userDocRef, async (docSnap) => {
      if (docSnap.exists()) {
        const userData = docSnap.data();
        const previousRole = role;
        setRole(userData.role || 'normal');

        if (previousRole !== userData.role && ['oro', 'platino', 'moderador'].includes(userData.role)) {
          const randomNumbersDoc = await getDoc(doc(db, 'system', 'randomNumbers'));
          if (randomNumbersDoc.exists()) {
            setRandomNumbers(randomNumbersDoc.data().numbers || []);
          }
        }

        if (userData.role === 'normal') {
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          const messagesQuery = query(
            collection(db, 'messages'),
            where('username', '==', username),
            where('timestamp', '>=', today)
          );

          const messagesSnapshot = await getDocs(messagesQuery);
          setDailyMessageCount(messagesSnapshot.size);

          if (messagesSnapshot.size >= 50) {
            setIsChatDisabled(true);
          }
        }
      }
    });

    const handleDisconnect = () => {
      updateDoc(userDocRef, {
        online: false,
        lastOnline: serverTimestamp(),
      });
    };

    window.addEventListener('beforeunload', handleDisconnect);

    return () => {
      unsubscribeMessages();
      unsubscribeUserDoc();
      window.removeEventListener('beforeunload', handleDisconnect);
      updateDoc(userDocRef, { online: false });
    };
  }, [username, role, avatarUrl]);

  const sendMessage = async (e) => {
    e.preventDefault();
  
    if (isChatDisabled) {
      alert("Has alcanzado tu límite diario de mensajes. Debes esperar hasta mañana o actualizar tu cuenta.");
      return;
    }
  
    const userDoc = await getDoc(doc(db, 'users', username));
    if (userDoc.exists() && userDoc.data().isBlocked) {
      alert("Has sido bloqueado por un moderador y no puedes enviar mensajes.");
      return;
    }
  
    if (newMessage.trim()) {
      const filteredMessage = filterMessage(newMessage);
  
      await addDoc(collection(db, 'messages'), {
        text: filteredMessage,
        username: username,
        avatarUrl: avatarUrl, // Ahora se asegura que el avatarUrl siempre esté actualizado
        timestamp: serverTimestamp(),
        role: role,
      });

      setNewMessage('');
      setDailyMessageCount(dailyMessageCount + 1);
  
      if (role === 'normal' && dailyMessageCount + 1 >= 10) {
        setIsChatDisabled(true);
        alert("Has alcanzado tu límite diario de mensajes. Debes esperar hasta mañana o actualizar tu cuenta.");
      }
  
      scrollToBottom();
    }
  };

  const handleImageUpload = async (e) => {
    if (isChatDisabled) {
      alert("Has alcanzado tu límite diario de mensajes. Debes esperar hasta mañana o actualizar tu cuenta.");
      return;
    }

    const file = e.target.files[0];
    if (file) {
      try {
        const imageRef = ref(storage, `chatImages/${username}-${Date.now()}`);
        await uploadBytes(imageRef, file);
        const imageUrl = await getDownloadURL(imageRef);

        await addDoc(collection(db, 'messages'), {
          imageUrl: imageUrl,
          username: username,
          avatarUrl: avatarUrl,
          timestamp: serverTimestamp(),
          role: role
        });

        setDailyMessageCount(dailyMessageCount + 1);

        if (role === 'normal' && dailyMessageCount + 1 >= 10) {
          setIsChatDisabled(true);
          alert("Has alcanzado tu límite diario de mensajes. Debes esperar hasta mañana o actualizar tu cuenta.");
        }

        scrollToBottom();
      } catch (error) {
        console.error("Error al subir la imagen: ", error);
      }
    }
  };

  const startRecording = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          const recorder = new MediaRecorder(stream);
          setMediaRecorder(recorder);
          recorder.start(); 
          setIsRecording(true);

          const audioChunks = [];

          recorder.ondataavailable = event => {
            audioChunks.push(event.data);
          };

          recorder.onstop = async () => {
            const audioBlob = new Blob(audioChunks);
            const audioRef = ref(storage, `chatAudios/${username}-${Date.now()}.webm`);
            setIsSendingAudio(true);

            await uploadBytes(audioRef, audioBlob);
            const audioUrl = await getDownloadURL(audioRef);

            await addDoc(collection(db, 'messages'), {
              audioUrl: audioUrl,
              username: username,
              avatarUrl: avatarUrl,
              timestamp: serverTimestamp(),
              role: role
            });

            setIsRecording(false);
            setIsSendingAudio(false);
            setDailyMessageCount(dailyMessageCount + 1);

            if (role === 'normal' && dailyMessageCount + 1 >= 10) {
              setIsChatDisabled(true);
              alert("Has alcanzado tu límite diario de mensajes. Debes esperar hasta mañana o actualizar tu cuenta.");
            }

            scrollToBottom();
          };
        })
        .catch(error => {
          console.error("Error al acceder al micrófono:", error);
          alert("No se pudo acceder al micrófono. Por favor, revisa los permisos.");
        });
    } else {
      alert("Tu navegador no soporta la grabación de audio.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();  
      setIsRecording(false);
    }
  };

  const handleAudioButtonClick = () => {
    if (isRecording) {
      stopRecording(); 
    } else {
      startRecording(); 
    }
  };

  const deleteMessage = async (messageId) => {
    try {
      await deleteDoc(doc(db, 'messages', messageId));
      alert("Mensaje eliminado correctamente.");
    } catch (error) {
      console.error("Error al eliminar el mensaje: ", error);
      alert("Hubo un error al intentar eliminar el mensaje.");
    }
  };

  const handleShareOnWhatsApp = () => {
    const message = encodeURIComponent("¡Únete a nuestro chat de Loterías! Aquí está el enlace: https://chatsrd.com/");
    const whatsappUrl = `https://api.whatsapp.com/send?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };

  const getMessageStyle = (msgRole) => {
    switch (msgRole) {
      case 'oro':
        return { backgroundColor: 'gold', color: 'black', padding: '12px', borderRadius: '15px', maxWidth: '85%' };
      case 'platino':
        return { 
          backgroundColor: 'lightblue', 
          color: 'black', 
          boxShadow: '0 0 10px #000', 
          border: '2px solid #000', 
          borderRadius: '20px', 
          padding: '12px', 
          maxWidth: '85%' 
        };
      case 'moderador':
        return { backgroundColor: 'green', color: 'white', padding: '12px', borderRadius: '15px', maxWidth: '85%' };
      default:
        return { backgroundColor: msgRole === username ? '#3f51b5' : '#e0e0e0', color: msgRole === username ? '#fff' : '#000', padding: '12px', borderRadius: '15px', maxWidth: '85%' };
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        
        <Box sx={{ flexGrow: 1, position: 'relative', borderRadius: '10px', boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)' }}>
          <IconButton 
            component={Link} 
            to="/settings" 
            sx={{ position: 'absolute', top: 10, right: 10 }}
          >
            <SettingsIcon />
          </IconButton>

          <Typography variant="h4" gutterBottom sx={{ padding: '10px 0', backgroundColor: '#3f51b5', color: 'white', textAlign: 'center', borderRadius: '10px 10px 0 0' }}>
            Bienvenido, {username}
          </Typography>

          {['oro', 'platino', 'moderador'].includes(role) && (
            <Box sx={{ p: 2, textAlign: 'center', backgroundColor: '#fff', borderRadius: '10px', mb: 2 }}>
              <Typography variant="h6">Números Recomendados de Hoy</Typography>
              <Typography variant="h4">{randomNumbers.join(' - ')}</Typography>
            </Box>
          )}

          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={() => navigate('/connected-users')}
            >
              Ver Usuarios Conectados
            </Button>

            <Button 
              variant="outlined" 
              color="secondary" 
              onClick={() => navigate('/predictions')}
              sx={{ ml: 2 }}
            >
              Predicciones
            </Button>
          </Box>

          <Paper sx={{ p: 2, mb: 3, height: { xs: 'auto', md: '60vh' }, overflowY: 'auto', borderRadius: '10px', backgroundColor: '#f5f5f5' }}>
            {messages.map(({ id, data }) => (
              <motion.div 
                key={id} 
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                style={{ display: 'flex', alignItems: 'center', marginBottom: '16px', flexDirection: data.username === username ? 'row-reverse' : 'row' }}
              >
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <Avatar src={data.avatarUrl} sx={{ ml: data.username === username ? 2 : 0, mr: data.username !== username ? 2 : 0 }} />
                </motion.div>
                <Paper 
                  sx={{ 
                    ...getMessageStyle(data.role),
                    textAlign: data.username === username ? 'right' : 'left',
                    position: 'relative'
                  }}
                >
                  <Typography variant="subtitle1">
                    <strong>{data.role === 'platino' && '⭐'} {data.username}</strong>
                  </Typography>
                  {data.text && (
                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: highlightKeyword(data.text) }} />
                  )}
                  {data.imageUrl && (
                    <Box sx={{ mt: 2 }}>
                      <img src={data.imageUrl} alt="Shared content" style={{ maxWidth: '100%', height: 'auto', maxHeight: '300px', borderRadius: '8px' }} />
                    </Box>
                  )}
                  {data.audioUrl && (
                    <Box sx={{ mt: 2 }}>
                      <audio controls>
                        <source src={data.audioUrl} type="audio/wav" />
                        Your browser does not support the audio element.
                      </audio>
                    </Box>
                  )}
                  <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                    {data.timestamp && new Date(data.timestamp.seconds * 1000).toLocaleTimeString()}
                  </Typography>
                  {role === 'moderador' && (
                    <IconButton 
                      sx={{ position: 'absolute', top: 0, right: 0 }}
                      onClick={() => deleteMessage(id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Paper>
              </motion.div>
            ))}
            <div ref={messagesEndRef} />
          </Paper>

          {isChatDisabled && (
            <Box sx={{ mb: 3 }}>
              <Alert severity="warning">
                Has alcanzado tu límite diario de mensajes. Debes esperar hasta mañana o actualizar tu cuenta.
              </Alert>
            </Box>
          )}

          <Box component="form" onSubmit={sendMessage} sx={{ display: 'flex', gap: 2, padding: '10px', backgroundColor: '#fff', borderRadius: '0 0 10px 10px' }}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="newMessage"
              label="Escribe un mensaje"
              name="newMessage"
              autoComplete="off"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              disabled={isChatDisabled}
              sx={{ borderRadius: '10px', flexGrow: 1 }}
            />
            <IconButton color="primary" component="label" disabled={isChatDisabled}>
              <PhotoCamera />
              <input type="file" hidden onChange={handleImageUpload} />
            </IconButton>

            {['oro', 'platino', 'moderador'].includes(role) && (
              <>
                <IconButton 
                  color="primary" 
                  sx={{ fontSize: 37 }} 
                  onClick={handleAudioButtonClick}
                  disabled={isChatDisabled}
                >
                  {isRecording ? <StopIcon fontSize="inherit" /> : <MicIcon fontSize="inherit" />}
                </IconButton>
                {isSendingAudio && <CircularProgress size={24} />} 
              </>
            )}

            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 'auto', mb: 'auto', borderRadius: '10px' }}
              disabled={isChatDisabled}
            >
              Enviar
            </Button>
          </Box>

          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Button 
              variant="contained" 
              color="success" 
              startIcon={<ShareIcon />} 
              onClick={handleShareOnWhatsApp}
              sx={{ fontSize: '14px', padding: '6px 16px', borderRadius: '8px' }}
            >
              Compartir en WhatsApp
            </Button>
          </Box>

          <audio ref={audioRef} src="/sounds/notification.mp3" />
        </Box>
      </Box>
    </Container>
  );
}

export default Chat;
